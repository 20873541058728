import styled from 'styled-components';

export const Ol = styled.ol``;

export const Ul = styled.ul`
  list-style-type: circle;
`;

export const Li = styled.li`
  margin: 0.8em 0;
`;
